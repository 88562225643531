import { get } from 'svelte/store';

import { partnerStore } from '@components/room/partner/store';
import { roomStore } from '@components/room/store';
import { userStore } from '@components/user/store';
import * as Sentry from '@sentry/sveltekit';

import { env } from '$env/dynamic/public';

if (env.PUBLIC_SENTRY_DSN) {
    Sentry.init({
        dsn: env.PUBLIC_SENTRY_DSN,
        tracesSampleRate: 0.01,
        sampleRate: 0.01,
        replaysOnErrorSampleRate: 0.01,
        replaysSessionSampleRate: 0.01,
        profilesSampleRate: 0.01,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.browserApiErrorsIntegration(),
            Sentry.httpClientIntegration(),
            Sentry.httpContextIntegration(),
        ],
        beforeSend: (event) => {
            const user = get(userStore).user;
            const room = get(roomStore).activeRoom;
            const roomPartner = get(partnerStore);

            if (user) {
                // eslint-disable-next-line no-param-reassign
                event.user = { id: user.id, username: user.username || user.displayName };
            } else {
                // пока что соберем только от авторизованных
                return null;
            }

            if (room) {
                // eslint-disable-next-line no-param-reassign
                event.contexts = {
                    ...(event.contexts || {}),
                    room: {
                        id: room.id,
                        status: room.status,
                        matchType: room.matchType ? room.matchType[0] : 'unknown',
                        partner: roomPartner?.id,
                    },
                };
            }

            return event;
        },
        tracesSampler: (context) => {
            if (context.name.includes('admin')) {
                return 0;
            }
            if (typeof context.parentSampled === 'boolean') {
                return context.parentSampled;
            }

            return 0.01;
        },
    });
}

/** @type {import('@sveltejs/kit').HandleClientError} */
export const handleError = Sentry.handleErrorWithSentry();
